"use client";
import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import CloudOffOutlinedIcon from "@mui/icons-material/CloudOffOutlined";

type AlertNetworkProps = {
  error: boolean;
  title?: string | undefined;
  onAction?: () => void | Promise<void>;
};

/**
 * ネットワーク問題でContext内でデータフェッチに失敗した際に表示する
 * @param props AlertNetworkProps
 * @returns
 */
export const AlertNetwork: React.FC<AlertNetworkProps> = (props) => {
  const { customT } = useClientTranslation();
  const { error, title = "データ", onAction } = props;

  const [showAlert, setShowAlert] = useState<boolean>(error);

  useEffect(() => {
    setShowAlert(error);
  }, [error]);

  const handleClose = useCallback(() => {
    setShowAlert(false);
  }, []);

  const handleAction = React.useCallback(
    async (e: React.MouseEvent) => {
      if (onAction) {
        await Promise.resolve(onAction());
      } else {
        e.preventDefault();
        window.location.reload();
      }
    },
    [onAction]
  );

  const action = useMemo(
    () => (
      <>
        <Button size="small" onClick={handleAction}>
          {customT("再読み込み")}
        </Button>
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </>
    ),

    [handleAction, handleClose, customT]
  );

  return (
    <Snackbar open={showAlert} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
      <Alert elevation={2} icon={<CloudOffOutlinedIcon />} severity="warning" action={action} onClose={handleClose}>
        {customT("{{title}}の取得に失敗しました", { title: customT(title) })}
      </Alert>
    </Snackbar>
  );
};
